@import "~font-awesome/css/font-awesome.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~tabler-ui/dist/assets/css/tabler.css";
@import "~tabler-ui/dist/assets/css/dashboard.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";

.tr-dark td, .tr-dark th {
    background: #e9ecef;
}

.tr-dark th {
    font-weight: bold;
}

.modal-backdrop {
    z-index: 2000 !important;
}

.modal {
    z-index: 2040 !important;
}

.tooltip {
    z-index: 2090 !important;
}

.popover hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.js-proposal-popover .badge, .js-customer-popover .badge {
    cursor: pointer;
}

.badge-pointer {
    cursor: pointer;
}

.js-proposal-popover .badge:hover, .js-customer-popover .badge:hover {
    filter: brightness(90%);
}

.status-line {
    font-size: 1.1rem;
}